import { useAccessToken } from "~/features/auth/api/auth-hooks";
import {
  getFrontPageQuery,
  getPageBySlugQuery,
  getPageByUuidQuery,
  getPagesListQuery,
} from "./page-querries";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { apiClient, constructHeaders } from "~/lib/client";
import {
  PageCreateRequest,
  PageResponse,
  PageUpdateRequest,
} from "~/types/backend";

export function useGetFrontPage() {
  const accessToken = useAccessToken();
  return useQuery(getFrontPageQuery(accessToken));
}

export function useGetPagesList() {
  const accessToken = useAccessToken();
  return useQuery(getPagesListQuery(accessToken));
}

export function useGetPageByUuid(uuid: string) {
  const accessToken = useAccessToken();
  return useQuery(getPageByUuidQuery(uuid, accessToken));
}

export function useGetPageBySlug(slug: string) {
  const accessToken = useAccessToken();
  return useQuery(getPageBySlugQuery(slug, accessToken));
}

type TCreatePagePayload = {
  data: PageCreateRequest;
};

export function useCreatePage() {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<PageResponse, AxiosError, TCreatePagePayload>({
    mutationFn: async ({ data }) => {
      const response = await apiClient.post(
        `pagebuilder/pages`,
        JSON.stringify(data),
        { headers }
      );
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["pages"],
      });
    },
  });
}

type TUpdatePagePayload = {
  data: PageUpdateRequest;
  uuid: string;
};

export function useUpdatePage() {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<PageResponse, AxiosError, TUpdatePagePayload>({
    mutationFn: async ({ data, uuid }) => {
      const response = await apiClient.put(
        `pagebuilder/pages/${uuid}`,
        JSON.stringify(data),
        { headers }
      );
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["pages"],
      });
    },
  });
}

type TDeletePagePayload = {
  uuid: string;
};

export function useDeletePage() {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<PageResponse, AxiosError, TDeletePagePayload>({
    mutationFn: async ({ uuid }) => {
      const response = await apiClient.delete(`pagebuilder/pages/${uuid}`, {
        headers,
      });
      return response.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["pages"],
      });
    },
  });
}
