import { queryFn } from "~/lib/client";
import { PageListResponse, PageResponse } from "~/types/backend";

export function getPagesListQuery(access_token: string | undefined = "") {
  return {
    queryKey: ["pages", "pages_list"],
    queryFn: queryFn<PageListResponse>(
      "GET",
      `/pagebuilder/pages`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}

export function getPageByUuidQuery(
  uuid: string,
  access_token: string | undefined = ""
) {
  return {
    queryKey: ["pages", "pages_by_uuid", uuid],
    queryFn: queryFn<PageResponse>(
      "GET",
      `/pagebuilder/pages/${uuid}`,
      access_token
    ),
    enabled: access_token !== undefined && uuid !== undefined,
  };
}

export function getPageBySlugQuery(
  slug: string,
  access_token: string | undefined = ""
) {
  return {
    queryKey: ["pages", "pages_by_slug", slug],
    queryFn: queryFn<PageResponse>(
      "GET",
      `/pagebuilder/pages/slug/${slug}`,
      access_token
    ),
    enabled: access_token !== undefined && slug !== undefined,
  };
}

export function getFrontPageQuery(access_token: string | undefined = "") {
  return {
    queryKey: ["pages", "frontpage"],
    queryFn: queryFn<PageResponse>(
      "GET",
      `/pagebuilder/pages/frontpage`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}
